import { call, put } from 'redux-saga/effects';

import { OrganisationApi } from '@shared/api/core-api';
import { HttpStatusCodes } from '@shared/constants/httpStatusCodes';
import * as TenantActionTypes from '@shared/redux/actions/tenant-actions';
import { ActionWithPayload } from '@shared/redux/actions/typings';
import { getAvailableCultures } from '@shared/util/cultures';
import { handleSagaError } from '@shared/util/handleSagaError';
import { changeLanguage } from '@shared/util/localisation-adapter';
import { getStoredOrDefaultCulture } from '@shared/util/user';

export function* verifyTenant(action: ActionWithPayload<{ tenant: string }>) {
  try {
    const { tenant } = action.payload;

    const {
      exists,
      isExternalLoginUsed,
      language,
    }: { exists: boolean; isExternalLoginUsed: boolean; language?: string } = yield call(
      OrganisationApi.getOrganisationSummary,
      tenant,
    );

    const { isCultureUserDefined } = getStoredOrDefaultCulture();
    if (!isCultureUserDefined) {
      const lowerCaseLanguage = language?.toLocaleLowerCase() ?? '';
      const dayJSLocale =
        getAvailableCultures().find((x) => x.languageCulture.toLocaleLowerCase() === lowerCaseLanguage)?.dayJSLocale ??
        'en-gb';
      changeLanguage(language ?? 'en-GB', dayJSLocale, false);
    }

    yield put({
      type: TenantActionTypes.TENANT_VERIFY_REQUEST_SUCCESS,
      payload: {
        exists,
        organisationLanguage: language,
        isExternalLoginUsed,
      },
    });
  } catch (e: any) {
    if (e?.status === HttpStatusCodes.NotFound) {
      yield put({
        type: TenantActionTypes.TENANT_VERIFY_REQUEST_SUCCESS,
        payload: {
          exists: false,
          organisationLanguage: '',
        },
      });
    } else {
      yield handleSagaError({
        type: TenantActionTypes.TENANT_VERIFY_REQUEST_FAILURE,
        error: e,
      });
    }
  }
}
